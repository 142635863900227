import React from 'react';
import {
    Box,
    Heading,
    Text,
    Link,
    Tooltip
} from '@chakra-ui/react';

import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from "react-icons/fa";

const MapMarker = ({ text }) => (
    <Box>
        <Link href="https://www.google.com/maps/dir//Willow+Creek+Interior+Design,+115+E+Broadway+Ave,+Jackson,+WY+83001/@43.4797973,-110.7625978,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x53531a42da8c21bf:0x7950a64f64371f15!2m2!1d-110.7604087!2d43.479768" target="_blank" rel="noopener noreferrer">
        
            <FaMapMarkerAlt 
                size="2.5rem"
            />
        
        </Link>
        
        </Box>
);

const ContactInfo = () => { 
    const defaultProps = {
        center: {
            lat: 43.47984398926643,
            lng: -110.76039837259033,
    },
    zoom: 18
};

    return (
        <Box>
            <Box mb="4">
                <Heading as="h4" fontSize="1.5rem" textTransform="uppercase">
                    Office &amp; Showroom
                </Heading>
                <Text>
                    1325 South Highway 89, Suite 109<br />
                    Jackson, WY 83001<br/>
                    <a href="tel:307-203-2183">307-203-2183</a>
                </Text>
            </Box>
            <Box mb="4">
                <Heading as="h4" fontSize="1.5rem" textTransform="uppercase">
                    Mailing Address
                </Heading>
                <Text>
                PO Box 4696<br />
                Jackson, WY 83001
                </Text>
            </Box>
            <Box height="300px" w="100%">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_KEY }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <MapMarker />
                </GoogleMapReact>
            </Box>


        </Box>
    )
}

export default ContactInfo;