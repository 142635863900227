import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Container,
  Box,
  Flex,
  Heading,
  useColorMode
} from '@chakra-ui/react'
import PageHero from '../components/PageHero/PageHero'
import SliceZone from '../components/SliceZone/SliceZone'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styled from '@emotion/styled'
import ContactForm from '../components/ContactForm/ContactForm'
import ContactInfo from '../components/ContactForm/ContactInfo'

const ImageBox = styled.div`
    transition: 0.5s ease;
    backface-visibility: hidden;
    position: relative; 
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    &:hover {
        opacity: 0.5;
    }
`;

const Contact = ({ data }) => {
    
const { colorMode } = useColorMode()

const document = data.prismicStaticPage.data
    
  return (
    <Layout>
      <Seo title="Willowcreek Design Group | Contact Us" />
      {document.hero_image.localFile ? (
            <PageHero 
              heroImage={document.hero_image?.localFile}
              alt={document.hero_image?.alt}
              heroText={document.hero_text?.text}
              btnLink={document.hero_button_link?.url}
              btnLabel={document.hero_button_label?.text}
            />
            ): null 
      }

      <Container maxW="container.xl">
          <Flex w="100%" my="12" flexWrap="wrap">
            <Box w={{base: '100%', lg: '50%'}} p="4">
                <ContactInfo />
            </Box>
            <Box w={{base: '100%', lg: '50%'}} p="4">
                <ContactForm />
            </Box>
          </Flex>
          
        <SliceZone sliceZone={document.body}/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
   {
        prismicStaticPage(uid: {eq: "contact"}) {
          _previewable
          data {
            page_title {
              text
            }
            hero_text {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            body {
              ... on PrismicStaticPageDataBodyTestimonials {
                id
                slice_type
                primary {
                  testimonials {
                    document {
                      ... on PrismicTestimonials {
                        id
                        data {
                          widget_title {
                            text
                          }
                          quote_block {
                            triangle_color
                            quote {
                                richText
                            }
                            person {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
`

export default Contact;
