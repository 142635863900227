import React, { useState, useRef } from "react"
import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Heading
} from "@chakra-ui/react"
import { CheckCircleIcon } from "@chakra-ui/icons"

import { useForm } from "react-hook-form"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const formRef = useRef(null);
  
  const onSubmit = data => {
    const formData = new FormData(formRef.current);
    //console.log(Array.from(formData.entries()));
    
    setProcessing(true);

    fetch('/.netlify/functions/send-contact-email', {
              method: 'POST',
              body: formData       
        })
        .then((response) => {
            if (response.status === 200) {
                //console.log(JSON.stringify(data));
                setSucceeded(true);
                reset();
            } else {
                setSucceeded(false);
                setProcessing(false);
                alert('Network error. Please try again later.')
                
            }
        })
    
      }

  const SuccessMessage = () => (
    <Box my="20" textAlign="center">
    <CheckCircleIcon 
      color="hit-gray.500"
      w={12}
      h={12}  
    />
    <Heading as="h5" size="md" mt={4}>
        Message submitted successfully!
        </Heading>
        <Text mt={2}>
    Thanks for your inquiry. We'll be in touch soon.
    </Text>
  </Box>
  )


  return (
    <>
      <Box w="100%" mb="24">
      { succeeded ? <SuccessMessage /> : (
        <>
        <Box>
            <Heading as="h4" fontSize="1.5rem" textTransform="uppercase" mb="4">Send Us A Message</Heading>
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <Flex w="100%" flexWrap="wrap">
                    <Box w={{base: '100%', lg: '50%'}} mb={8} pr={{base: 0, lg: '2'}}>
                      <FormControl id="name">
                        <FormLabel>Your Name<Text color="#bf1650" as="span">*</Text></FormLabel>
                        <Input type="text"
                            {...register("name", {required: true} )}
                        />
                        {errors.name && <Text color="#bf1650">Please enter your name.</Text>}
                      </FormControl>
                    </Box>
                    <Box w={{base: '100%', lg: '50%'}} mb={8} pl={{base: 0, lg: '2'}}>
                    <FormControl id="email">
                    <FormLabel>Email address<Text color="#bf1650" as="span">*</Text></FormLabel>
                    <Input type="email" 
                       {...register("email", 
                        {
                          required: true, 
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                         }
                      }
                      )}
                       />
                       {errors.email && <Text color="#bf1650">Please enter a valid email address.</Text>}
                  </FormControl>
                    </Box>
                </Flex>
                <Box w={{base: '100%', lg: '50%'}} mb={8}>
                    <FormControl id="email">
                    <FormLabel>Phone Number</FormLabel>
                    <Input type="phone" 
                       {...register("phone")}
                       />
                  </FormControl>
                    </Box>
                         
            <Box>
                <FormControl id="message" mb={8}>
                <FormLabel>Message<Text color="#bf1650" as="span">*</Text></FormLabel>
                    <Textarea placeholder=""
                        {...register("message", {required: true} )}
                        />
                        {errors.message && <Text color="#bf1650">Please enter a message.</Text>}
                </FormControl>
            </Box>
              <Box mt="4">
              <Button 
                disabled={!isValid || processing}
                type="submit"
                mt={4}
                isLoading={processing}
                loadingText='Submitting'
                >
                  Submit
              </Button>
              </Box>
            </form>
        </Box>
        </>
      )
}
      </Box>
    </>
  )
}

export default ContactForm
